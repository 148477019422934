
import {
  defineComponent,
  computed,
  onMounted,
  watch,
  nextTick,
  onBeforeMount,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTHeaderNoMenu from "@/layout/header/HeaderNoMenu.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbarNoMenu from "@/layout/toolbar/ToolbarNoMenu.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";
import { loginRequest } from "@/authConfig";

export default defineComponent({
  name: "Layout",
  components: {
    KTHeaderNoMenu,
    KTFooter,
    KTToolbarNoMenu,
    KTScrollTop,
    KTUserMenu,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    onBeforeMount(() => {
      const store = useStore();
      const menu = computed(() => store.getters.menu);
      const checkIsBacheca = () => {
        return menu.value[0].pages.some((element) => {
          return element.heading && element.heading.includes("Bacheca");
        });
      };

      const isMenuLoaded = computed(() => store.getters.isMenuLoaded);

      if (!isMenuLoaded.value && !route.path.includes("/vt")) {
        store.dispatch("getMenuFromApi", 0);
      }
    });

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
      // }
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      menuLoaded: computed(() => store.getters.isMenuLoaded),
      statusMenu: computed(() => store.getters.statusMenu),
    };
  },
});
