<template>
  <!--begin::Toolbar-->
  <div class="toolbar" id="kt_toolbar">
    <!--begin::Container-->
    <div
      id="kt_toolbar_container"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
      class=""
    >
      <!--begin::Page title-->
      <div
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        class="page-title align-items-center me-3 mb-5 mb-lg-0"
      >
        <div class="row d-flex">
          <div class="col-9 d-flex">
            <h1 class="align-items-center fw-bolder my-2 fs-3 page-title">
              {{ title }}
            </h1>
          </div>

          <div class="col-sm-3">
            <div class="text-end my-2 pt-1 d-none d-lg-block">
              <span> Federazione Italiana Tennis e Padel </span>
            </div>
          </div>
        </div>

        <!--end::Page title-->
      </div>
      <!--end::Container-->
    </div>
  </div>
  <!--end::Toolbar-->
</template>

<script>
import { defineComponent } from "vue";
/* import Dropdown1 from "@/components/dropdown/Dropdown1.vue"; */
import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    path: String,
  },
  components: {
    /*    Dropdown1, */
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
</script>

<style>
@media (min-width: 992px) {
  .toolbar-fixed .toolbar {
    /* position: fixed; */
    position: inherit;
    border-top: 1px solid #eff2f5;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
    z-index: 100px;
  }
}

@media (min-width: 992px) {
  .content {
    padding: 0 0;
  }
}

@media (max-width: 992px) {
  .toolbar-fixed .toolbar {
    display: none;
  }
}
</style>
