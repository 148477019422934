
import { defineComponent } from "vue";
import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  data() {
    return {
      isLight: false,
    };
  },
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {},
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
